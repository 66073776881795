<template>
  <nav class="navbar is-transparent">
    <div class="navbar-brand">
      <a
        class="navbar-item"
        href="/"
        v-if="
          this.channelSettings.content &&
          this.channelSettings.content['channel-logo']
        "
      >
        <image-component
          :path="channelSettings.content['channel-logo']"
          class="main-logo"
          alt="Main logo"
        />
      </a>
      <div
        role="button"
        class="navbar-burger"
        data-target="navMenu"
        aria-label="menu"
        aria-expanded="false"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

    <div class="navbar-menu" id="navMenu">
      <div class="navbar-start" style="">
        <template v-for="(item, index) in getSubHeaderLinks">
          <template v-if="item.children">
            <div class="navbar-item has-dropdown is-hoverable" :key="item.name">
              <router-link class="navbar-link" :to="item.url" exact>
                {{ item.name }}
              </router-link>
              <div class="navbar-dropdown is-boxed">
                <template v-for="itemChild in item.children">
                  <router-link
                    class="navbar-item"
                    :to="itemChild.url"
                    :key="itemChild.name"
                    exact
                  >
                    {{ itemChild.name }}
                  </router-link>
                </template>
              </div>
            </div>
          </template>
          <template v-else>
            <router-link
              class="navbar-item"
              :to="item.url"
              :key="item.name"
              exact
            >
              {{ item.name }}
            </router-link>
          </template>
        </template>
      </div>

      <div class="navbar-end">
        <template v-if="authUser">
          <a class="navbar-item" href="javascript:void(0);" @click="logout">
            logout
          </a>
        </template>
        <template v-else>
          <router-link class="navbar-item" to="/login"> login </router-link>
        </template>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ImageComponent from './base-components/ImageComponent';

export default {
  name: 'Header',
  components: { ImageComponent },
  data() {
    return {};
  },
  methods: {
    ...mapActions(['DO_LOGOUT', 'GET_API_KEY']),
    logout() {
      this.DO_LOGOUT().then(() => {
        this.$store.commit('RESET_STATE');
        this.$router.go(this.$router.currentRoute);
      });
    },
  },
  computed: {
    ...mapState({
      channelMenu: (state) => state.channel.menu,
      authUser: (state) => state.auth.user,
      channelSettings: (state) => state.channel.settings,
    }),
    getSubHeaderLinks() {
      return this.channelMenu;
    },
  },
  mounted() {
    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(
      document.querySelectorAll('.navbar-burger'),
      0
    );
    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {
      // Add a click event on each of them
      $navbarBurgers.forEach((el) => {
        el.addEventListener('click', () => {
          // Get the target from the "data-target" attribute
          const target = el.dataset.target;
          const $target = document.getElementById(target);

          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle('is-active');
          $target.classList.toggle('is-active');
        });
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.navbar-start {
  flex-grow: 1;
  justify-content: center;
}

.navbar-item,
.navbar-link {
  color: gray;
  text-transform: uppercase;
}
.navbar-item.router-link-active,
.navbar-link.router-link-active {
  color: black;
  font-weight: 600;
}
.main-logo {
  max-height: 56px;
  width: 112px;
}
</style>
